<template>
  <gf-print>

    <!-- Header -->

    <template slot="title" slot-scope="slot">
      <span :class="{ 'text--plain-color': slot.plain }" class="span span--title">Packing Slip</span>
    </template>

    <template slot="Company Details" slot-scope="slot">
      <span :class="{ 'text--plain-color': slot.plain }" class="print-text font-size-md font-weight-light text-white">{{
        so.customer.name }}</span>
      <span :class="{ 'text--plain-color': slot.plain }" class="print-text font-size-md font-weight-light text-white">{{
        billTo }}</span>
    </template>

    <template slot="Receiver" slot-scope="slot">
      <el-col :class="{ 'margin-plain-color': slot.plain }" :span="8" class="base-card__header__details__row__content">
        <span :class="{ 'text--plain-color': slot.plain }"
          class="print-text font-size-md font-weight-bold vertical-spacing text-white">Ship To</span> <!-- Header -->
        <span :class="{ 'text--plain-color': slot.plain }"
          class="print-text font-size-md font-weight-light secondary-vertical-spacing text-white">{{ shipTo }}</span>
      </el-col>
      <el-col :class="{ 'margin-plain-color': slot.plain }" :offset="6" :span="10"
        class="base-card__header__details__row__content">
        <div :class="{ 'border--plain-color': slot.plain }" class="base-card__header__details__row__content__data">
          <span :class="{ 'text--plain-color': slot.plain }"
            class="print-text font-size-md font-weight-bold vertical-spacing text-white">Order Number</span>
          <span :class="{ 'text--plain-color': slot.plain }"
            class="print-text font-size-md font-weight-light vertical-spacing text-white">{{ so.so_number }}</span>
        </div>
        <div :class="{ 'border--plain-color': slot.plain }" class="base-card__header__details__row__content__data">
          <span :class="{ 'text--plain-color': slot.plain }"
            class="print-text font-size-md font-weight-bold vertical-spacing text-white">Shipped</span>
          <span :class="{ 'text--plain-color': slot.plain }"
            class="print-text font-size-md font-weight-light vertical-spacing text-white">{{ $DateFormat(so.shipment_date)
            }}</span>
        </div>
        <div :class="{ 'border--plain-color': slot.plain }" class="base-card__header__details__row__content__data">
          <span :class="{ 'text--plain-color': slot.plain }"
            class="print-text font-size-md font-weight-bold vertical-spacing text-white">Email</span>
          <span :class="{ 'text--plain-color': slot.plain }"
            class="print-text font-size-md font-weight-light vertical-spacing text-white">{{ so.email }}</span>
        </div>
      </el-col>
    </template>

    <!-- Table Content -->

    <template slot="Data Table" slot-scope="slot">
      <table class="base-card__description__table">
        <thead class="thead">
          <tr>
            <th :class="{ 'text--plain-color': slot.plain }"
              class="print-text th font-size-md font-weight-bold text-dark text-center">Item</th>
            <th :class="{ 'text--plain-color': slot.plain }"
              class="print-text th font-size-md font-weight-bold text-dark text-right">Quantity</th>
            <th :class="{ 'text--plain-color': slot.plain }"
              class="print-text th font-size-md font-weight-bold text-dark text-left">Unit</th>
            <th :class="{ 'text--plain-color': slot.plain }" class="print-text th font-size-md font-weight-bold text-dark">
              Description</th>
            <th :class="{ 'text--plain-color': slot.plain }"
              class="print-text th font-size-md font-weight-bold text-dark text-right">Unit Price</th>
            <th :class="{ 'text--plain-color': slot.plain }"
              class="print-text th font-size-md font-weight-bold text-dark text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in so.items" :key="item.id">
            <td :class="{ 'text--plain-color': slot.plain }"
              class="print-text td font-size-md font-weight-normal text-dark text-center">{{ index + 1 }}</td>
            <td :class="{ 'text--plain-color': slot.plain }"
              class="print-text td font-size-md font-weight-normal text-dark text-right">{{ item.quantity }}</td>
            <td :class="{ 'text--plain-color': slot.plain }"
              class="print-text td font-size-md font-weight-normal text-dark text-left">{{ item.product.units }}</td>
            <td v-if="item.variant.is_pack === 0" :class="{ 'text--plain-color': slot.plain }"
              class="print-text td font-size-md font-weight-normal text-dark">
              <span v-if="item.product.name === item.variant.name">{{ item.variant.name }}</span>
              <span v-else>{{ item.product.name + ' - ' + item.variant.name }}</span>
              <div v-for="(material) in item.product.materialIds" :key="material.id">
                - {{ material.quantity }} {{ material.variant.product.units }} x {{ material.variant.name ===
                  material.variant.product.name ? material.variant.name : `${material.variant.product.name} -
                                ${material.variant.name}` }}
              </div>
            </td>
            <td v-else :class="{ 'text--plain-color': slot.plain }"
              class="print-text td font-size-md font-weight-normal text-dark">
              <span v-if="item.product.name === item.variant.variant.name">{{ item.variant.variant.name }} -
                {{ item.variant.name }} (Pack of {{ item.variant.pack_size }})</span>
              <span v-else>{{ item.product.name }} - {{ item.variant.variant.name }} - {{ item.variant.name }} (Pack of {{
                item.variant.pack_size }})</span>
              <div v-for="(material) in item.product.materialIds" :key="material.id">
                - {{ material.quantity * item.variant.pack_size }} {{ material.variant.product.units }} x {{
                  material.variant.name === material.variant.product.name ? material.variant.name :
                  `${material.variant.product.name} - ${material.variant.name}` }}
              </div>
            </td>
            <td :class="{ 'text--plain-color': slot.plain }"
              class="print-text td font-size-md font-weight-normal text-dark text-right">{{ item.cost }}</td>
            <td :class="{ 'text--plain-color': slot.plain }"
              class="print-text td font-size-md font-weight-normal text-dark text-right">{{ $NumberFormat(item.cost *
                item.quantity) }}</td>
          </tr>
        </tbody>
      </table>
    </template>

    <!-- Summary Content -->

    <template slot="Summary" slot-scope="slot">
      <div class="base-card__breakdown__desc__header">
        <span :class="{ 'text--plain-color': slot.plain }" class="print-text font-size-md font-weight-bold text-dark">Total
          Summary:</span>
      </div>
      <div class="base-card__breakdown__desc__accounts">
        <span :class="{ 'text--plain-color': slot.plain }"
          class="print-text font-size-md font-weight-normal text-dark">Total Units</span>
        <span :class="{ 'text--plain-color': slot.plain }" class="print-text font-size-md font-weight-normal text-dark">{{
          $NumberFormat(sm.units) }}</span>
      </div>
      <div v-if="sm.is_inclusive != 2" class="base-card__breakdown__desc__accounts">
        <span :class="{ 'text--plain-color': slot.plain }"
          class="print-text font-size-md font-weight-normal text-dark">Subtotal</span>
        <span :class="{ 'text--plain-color': slot.plain }" class="print-text font-size-md font-weight-normal text-dark">{{
          $NumberFormat(sm.subtotal) }}</span>
      </div>
      <div v-if="sm.is_inclusive != 2">
        <div v-for="(vat, index) in sm.vats" :key="index" class="base-card__breakdown__desc__accounts">
          <span :class="{ 'text--plain-color': slot.plain }"
            class="print-text font-size-md font-weight-normal text-dark">{{ (sm.is_inclusive) ? 'Including' : 'Plus' }} VAT
            ({{ vat.rate }}%)</span>
          <span class="print-text font-size-md font-weight-normal text-dark">{{ $NumberFormat(vat.value) }}</span>
        </div>
      </div>
    </template>

    <template slot="Total" slot-scope="slot">
      <span :class="{ 'text--plain-color': slot.plain }" class="print-text font-size-md font-weight-bold text-dark">TOTAL
        AMOUNT</span>
      <span :class="{ 'text--plain-color': slot.plain }" class="span span--amount">{{ $NumberFormat(sm.total) }}</span>
      <span v-if="sm.is_inclusive != 2" :class="{ 'text--plain-color': slot.plain }"
        class="print-text font-size-md font-weight-bold text-dark">Taxes Included</span>
    </template>

  </gf-print>
</template>

<style lang="scss" scoped>
.text--plain-color {
  color: #000 !important;
}

.border--plain-color {
  border-bottom: 1px solid #000 !important;
}

.padding-plain-color {
  padding-top: 0px !important;
}

.border-text-plain-color {
  color: #000 !important;
  border-bottom: 1px solid #000 !important;
}

.span {
  &--title {
    font-size: calc(1rem + 1.15vw) !important;
    font-weight: 600;
    color: #fff;
  }

  &--amount {
    color: #F64E60;
    font-weight: 600 !important;
    font-size: calc(0.8rem + 1vw);
    margin-bottom: 0.15rem;
  }
}

.vertical-spacing {
  padding-bottom: 0.15rem;
}

.secondary-vertical-spacing {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.horizontal-spacing {
  padding-right: 0.35rem;
}

// Receiver Template
.base-card__header__details__row__content {
  padding-right: 1rem;
  display: flex;
  flex-direction: column;

  &:nth-of-type(2) {
    padding-right: 0 !important;
  }

  &:nth-of-type(3) {
    padding-right: 0 !important;
  }

  &__data {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #fff solid;
    margin-top: 0.3rem;

    &:first-of-type {
      margin-top: 0px;
    }
  }

  &__contacts {
    display: flex;
  }
}

// Data Table Template
.base-card__description__table {
  border-collapse: collapse;

  .th {
    vertical-align: text-top;
    text-align: left;
    padding: 0.5rem 0;
    border-bottom: 1px #000 solid !important;
    padding: 2px 10px !important;

    &--right {
      text-align: right;
    }
  }

  .td {
    page-break-after: auto !important;

    &--right {
      text-align: right;
    }

    text-align: left;
    border-bottom: 1px #000 solid !important;
    padding: 2px 10px !important;
  }
}

// Summary Template
.base-card__breakdown__container {
  padding: 3% 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.base-card__breakdown__desc {

  &__header {
    margin-bottom: 0.8rem;
  }

  &__accounts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    padding-bottom: 0.2rem;
    border-bottom: 1px #000 solid;
  }
}
</style>

<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module'

// Services
import SalesOrderService from '@/services/v1/SalesOrder'
import SalesShipmentService from '@/services/v1/SalesShipment'

export default {
  data() {
    return {
      so: {
        so_number: null,
        email: null,
        customer: {
          id: null
        },
        shipment_date: null,
        items: []
      },
      sm: {
        packed: null,
        is_inclusive: 0,
        message: null,
        units: null,
        subtotal: null,
        vats: [],
        total: null,
        is_fulfilled: null,
        items: [],
        bill_to: {
          label: null
        },
        ship_to: {
          label: null
        },
        shipment_date: null,
        ship_from: {
          name: null
        }
      },
      plain: false,
      // services
      soService: null,
      pmService: null
    }
  },
  computed: {
    billTo() {
      let address = ''

      if (this.sm.bill_to.address_line_1) address += this.sm.bill_to.address_line_1
      if (this.sm.bill_to.address_line_2) address += ' ' + this.sm.bill_to.address_line_2
      if (this.sm.bill_to.city) address += ', ' + this.sm.bill_to.city
      if (this.sm.bill_to.state) address += ', ' + this.sm.bill_to.state

      return address
    },
    shipTo() {
      let address = ''

      if (this.sm.ship_to.address_line_1) address += this.sm.ship_to.address_line_1
      if (this.sm.ship_to.address_line_2) address += ' ' + this.sm.ship_to.address_line_2
      if (this.sm.ship_to.city) address += ', ' + this.sm.ship_to.city
      if (this.sm.ship_to.state) address += ', ' + this.sm.ship_to.state

      return address
    }
  },
  methods: {
    async getSalesOrder() {
      try {
        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.get()
        this.so = result.data
      } catch (error) {
        this.$Error(error)
      }
    },
    async getShipment() {
      try {
        const ssService = new SalesShipmentService(this.$route.params.id, this.$route.params.sid)
        const result = await ssService.get()
        this.sm = result.data
      } catch (error) {
        this.$Error(error)
      }
    },
    async loadPage() {
      await this.getSalesOrder()
      await this.getShipment()

      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Orders' },
        { title: this.so.so_number },
        { title: 'Shipments' },
        { title: 'Print' }
      ])
    }
  },

  async mounted() {
    this.loadPage()
  }
}
</script>
